/* Footer */

  .footer {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
    font-size: calc(#{$default-font-size} - 0.05rem);
    font-family: $type1;
    font-weight: 400;
    .footer-wrap {
      background: $footer-bg;
      color: $footer-color;
      padding: 30px 1rem;
      border-top: 1px solid rgba($black, .06);
      a {
        color: $body-color;
        font-size: inherit;
      }
      @media (max-width: 991px) {
        margin-left: 0;
        width: 100%;
      }
    }
}
