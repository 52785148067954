.page-body-wrapper {
  padding-left: 0;
  padding-right: 0;
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
    .content-wrapper {
      width: 100%;
      max-width: none;
    }
  }
}

.horizontal-menu {
  &.fixed-on-scroll {
    + .page-body-wrapper {
      padding-top: 4rem;
    }
  }
}

.main-panel {
  background: $content-bg;
  width: 100%;
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @include media-breakpoint-down(md) {
    padding-top: 80px;
  }
}

.content-wrapper {
  padding: 1.5rem 0;
  @include make-container();
  min-height: calc(100vh - #{$horizontal-navbar-height} - #{$footer-height});
  @include flex-grow(1);
}