/* Demo Styles */
// Add spacing to Boostrap components for demo purpose
.template-demo {
  > .btn {
    @extend .mt-3;
    @extend .me-2;
  }

  > .btn-toolbar {
    @extend .mt-3;
    @extend .me-3;
  }

  > .btn-group,
  >  .btn-group-vertical {
    @extend .mt-2;
    @extend .me-2;
  }
  .circle-progress {
    @extend .mt-2;
  }

  > h2,
   > h3,
   > h4,
   > h5,
   > h6,
  > h1 {
    border-top: 1px solid $border-color;
    padding: 0.5rem 0 0;
  }

  > .dropdown {
    display: inline-block;
    @extend .me-2;
    margin-bottom: 0.5rem;
  }

  nav {

    &:last-child {
      .breadcrumb {
        margin-bottom: 0;
      }
    }
  }


  .circle-progress {
    padding: 15px;
  }

  .circle-progress-block {
    @extend .mb-3;
    @extend .px-2;
  }
}
.documentation {
  padding-top: 0;
  max-width: 100%;
  .content-wrapper {
    padding-top: 0;
    min-height: calc(100vh - #{$footer-height});
  }
}
.doc-table-contents,
.doc-header {
  position: fixed;
}
.doc-header {
  position: fixed;
  top: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 2;
  background: $content-bg;
  z-index: 999;
}

.doc-content {
  padding-top: 140px;
  @media (max-width: 991px) {
    padding-top: 85px;
  }
  
}

.demo-vector-map {
  width: 100%;
  height: 500px;
}
#proBanner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: #000;
  .card-body-padding {
    padding-top: .55rem ;
    padding-bottom: .55rem;
    padding-left: 2rem;
    padding-right: 2rem;
    @media (max-width: 766px) {
    padding-left: 1rem;
    padding-right: 1rem;
    }
  }
  .buy-now-text {
    color: $white;
    @media (max-width: 766px) {
      font-size: 10px;
      line-height: 1.5;
    }
  }
  .btn {
    &.buy-now-btn {
      background: transparent;
      color: #88b6ff;
      border-radius: 10px;
      // padding: 0.625rem 1.25rem;
      padding: 1rem 0;
      border-radius: 4px;
      font-weight: bold;
      font-size: 1rem;
      @media (max-width: 766px) {
        min-width: 80px;
        padding: 1rem .5rem;
        font-size: 12px;
      }
    }
    &#bannerClose { 
      i {
        margin-right: 1rem;
        font-size: 1.25rem;
        @media (max-width: 766px) {
          font-size: 1rem;
          margin-right: 0;
        }
      }
    }
  }
  a {
    text-decoration: none;
    i {
      font-size: 1.25rem;
      @media (max-width: 766px) {
        font-size: 1rem;
      }
    }
  }
  
}
.proBanner-padding-top {
  padding-top: $buy-nowbanner-padding-top !important;
}